import { useContext, useEffect, useState } from 'react';
import useTimer from 'easytimer-react-hook';
import { AssetSvg } from '../../../assets';
import { CallType, VideoViewType } from '../../../configs';
import { useAppSelector, VideoCallContext, VRIAppStateType } from '../../../core';

interface ITopActionBarProp {
  isOperatorRequired: boolean;
}

export function TopActionBar({ isOperatorRequired }: ITopActionBarProp) {
  const {
    room,
    viewType,
    setViewType,
    callType,
    selectedParticipantInfo,
    participantsInfo,
  } = useContext(VideoCallContext);
  const [selectedParticipantName, setSelectedParticipantName] = useState('');
  const videoCall = useAppSelector((state: VRIAppStateType) => state.videoCall);
  const [timer] = useTimer();
  const hasRemoteParticipant = room?.participants.size;

  const handleViewClick = () => {
    if (viewType === VideoViewType.GridView) {
      setViewType(VideoViewType.SpeakerView);
    } else {
      setViewType(VideoViewType.GridView);
    }
  };

  useEffect(() => {
    if (
      callType === CallType.VideoConference &&
      isOperatorRequired &&
      !videoCall.hasOperator
    ) {
      return;
    }
    timer.start();
  }, [videoCall, timer, callType, hasRemoteParticipant, isOperatorRequired]);

  useEffect(() => {
    if (!Object.keys(participantsInfo).length) {
      return;
    }
    if (selectedParticipantInfo) {
      setSelectedParticipantName(
        participantsInfo[selectedParticipantInfo.participant.identity].name
      );
    } else {
      setSelectedParticipantName('');
    }
  }, [participantsInfo, selectedParticipantInfo]);

  return (
    <nav className="d-flex justify-content-between w-100 conference-navbar px-3 py-2 align-items-center">
      <div>
        <div className="conference-name">
          <p className="mb-0">{selectedParticipantName}</p>
        </div>
        <div className="call-timer">{timer.getTimeValues().toString()}</div>
      </div>
      <div>
        <button
          className="btn btn-viewToggle ml-auto view-toggle-conference"
          onClick={handleViewClick}
        >
          <div className="grid-view-toggle">
            {viewType === VideoViewType.SpeakerView ? (
              <>
                <span className="mr-2">
                  <AssetSvg.GridView />
                </span>
                <span>Grid View</span>
              </>
            ) : (
              <>
                <span className="mr-2">
                  <AssetSvg.SpeakerView />
                </span>
                <span>Speaker View</span>
              </>
            )}
          </div>
        </button>
      </div>
    </nav>
  );
}
