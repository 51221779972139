import React, { useEffect } from "react";
import { ApiSchemaCompanyByIdData, CompanyModalityRequestModel, CompanyModalityResponseModel, } from "../../../models";
import { useFunctionalityCompany, useFunctionalityModalityTab } from "../../../pages/hooks";
import { useState } from "react";
import { AppConstants, AppRouteUi, topLoaderProgress } from "../../../configs";
import { useAppSelector,  VRIAppStateType} from "../../../core";
import LoadingBar from "react-top-loading-bar";
import { NoDataComponent } from "../../admin/noDataComponent";
import { ModalityResponseModel } from "../../../models/api/modality";
import { mutate } from "swr";

type Props = {
  companyProfileData?: ApiSchemaCompanyByIdData;
  editable: boolean;
};
export function ModalityTab({ editable }: Props) {
  const [loaderProgress, setLoaderProgress] = useState(0);
  const companyId = AppRouteUi.Company.Profile.useParam();
  const resellerId = AppRouteUi.Reseller.Profile.useParam();
  const [companyModalityDetails, setCompanyModalityDetails] = useState<ModalityResponseModel[]>();
  const [onUpdateSuccess, setOnUpdateSuccess] = useState(false);
  const userRole = useAppSelector((state: VRIAppStateType) => state.auth.profile?.userRole);
  const [isLoadingModality, setIsLoadingModality] = useState<boolean>(false);

  const { LoadModality, pagedModalityUrl } = useFunctionalityModalityTab({
    CompanyId: companyId ? companyId : resellerId,
    setLoaderProgress,
  });

  useEffect(() => {
    LoadModality().then((data) => {
      setCompanyModalityDetails(data)
      setIsLoadingModality(true);
    });
  }, [onUpdateSuccess])

  const companyModalities: ModalityResponseModel[] =
    companyModalityDetails || [];
  const { onUpdateCompanyModality } = useFunctionalityCompany({
    companyId: companyId ? companyId : resellerId,
    setLoaderProgress: setLoaderProgress
  });
  const checkIsAllEnabled = () => {
    const notAllModalitysEnabled = companyModalities.find((x) => x.status === false);
    if (notAllModalitysEnabled) return false;
    return true;
  };
  let isAll: boolean = checkIsAllEnabled();
  useEffect(() => {
  }, [onUpdateSuccess]);

  const onModalityToggleHandler = (modality: ModalityResponseModel) => {
    modality = { ...modality, status: !modality.status };
    const body: CompanyModalityRequestModel = {
      modalityId: modality.id,
      status: modality.status,
    };
    onUpdateCompanyModality(companyId ? companyId : resellerId, body)?.then(() => {
      setOnUpdateSuccess((prev) => !prev);
      setLoaderProgress(topLoaderProgress.complete);
      mutate(pagedModalityUrl)
    });
  };
  const onAllUpdateToggle = () => {
    isAll = !isAll;
    const body: CompanyModalityRequestModel = {
      isAll: true,
      status: isAll
    };
    onUpdateCompanyModality(companyId ? companyId : resellerId, body)?.then(() => {
      setOnUpdateSuccess((prev) => !prev);
      setLoaderProgress(topLoaderProgress.complete);
      mutate(pagedModalityUrl)
    });
  };

  const showModalityName = (modality: CompanyModalityResponseModel) => {
    if (userRole === AppConstants.UserRoles.CompanyAdmin) {
      if (modality?.status) return true;
      return false;
    } else {
      return true;
    }
  }

  return (
    <>
      <LoadingBar
        progress={loaderProgress}
        onLoaderFinished={() => setLoaderProgress(0)}
      />
      {
        !isLoadingModality ? <NoDataComponent title="Loading..." />
          :
          <>
            {
              companyModalityDetails?.length! > 0 ?
                <div className="pt-3 pb-3 pb-sm-0 ">
                  <div className="component-card mb-3">
                    <div className="component-card-header d-flex align-items-center justify-content-between border-top border-left border-right">
                      <div className="col">{editable ? 'Add Modalitys to this Company profile-VRI & OPI' : 'Available modalitys for VRI&OPI'} </div>
                      {(companyModalityDetails && companyModalityDetails?.length > 0 && editable) && (
                        <div className="col text-right">
                          <button
                            type="button"
                            className={checkIsAllEnabled() ? "btn btn-toggle active" : "btn btn-toggle"}
                            onClick={() => {
                              onAllUpdateToggle();
                            }}
                          >
                            <div className="handle"></div>
                          </button>
                          <span className="toggle-button-label">All</span>
                        </div>
                      )}
                    </div>
                    <div className="component-card-body border">
                      <div className="row flex-wrap px-3">
                        {companyModalityDetails && companyModalityDetails?.map((modality, index) => (
                          <>
                            {
                              showModalityName(modality) && <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 px-0 my-3" key={modality.id}>
                                {editable && (
                                  <button
                                    type="button"
                                    className={
                                      modality?.status ? "btn btn-toggle active" : "btn btn-toggle"
                                    }
                                    onClick={() => {
                                      onModalityToggleHandler(modality);
                                    }}
                                  >
                                    <div className="handle"></div>
                                  </button>
                                )}
                                <span className="toggle-button-label">
                                  {modality?.name}
                                </span>
                              </div>
                            }
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                : <NoDataComponent title="No Data Found" />
            }
          </>
      }
    </>
  );
}
