import { Alert } from 'react-bootstrap';
import { BottomActionBar, Chat, Room, TopActionBar } from '../../components';
import { AppRouteUi, CallStatusType, CallType } from '../../configs';
import { VideoCallContext, useAppSelector, VRIAppStateType } from '../../core';
import { ModalHangupCall, ModalVideoConferenceLink } from '../../features';
import { searchInCurrentURL } from '../../utils';
import { useVideoCall } from '../hooks';

export function VideoCallPageV2() {

  // console.log("hello, this is ghadir from VideoCallPageV2");
  const isVideoConferenceCall = searchInCurrentURL(
    AppRouteUi.VideoConference.Root
  );
  const callType: CallType.VideoConference | CallType.Video =
    isVideoConferenceCall ? CallType.VideoConference : CallType.Video;
  const isOperatorRequired = isVideoConferenceCall
    ? searchInCurrentURL('/true')
    : false;
  const roomId = isVideoConferenceCall
    ? AppRouteUi.VideoConference.Room.useParam()
    : AppRouteUi.VideoCall.Room.useParam();
  const operatorId = roomId
    ? AppRouteUi.VideoConference.Room.InterpreterId()
    : '';

    ///room/6877f0b4-63e4-44e9-b9f5-e8c5c9e2bd1c
  const webToken = useAppSelector((state: VRIAppStateType) => ({
    VRIToken: state.auth.VRItoken,
  }));

  const {
    appData,
    room,
    VideoCallContextValue,
    callHangupHandler,
    setShowHangupModal,
    showChat,
    setShowChat,
    callOperator,
    showHangupModal,
    countdownSeconds,
    isCountdownRunning,
  } = useVideoCall({
    roomId,
    callType,
    isOperatorRequired,
    isVideoConferenceCall,
    operatorId,
  });

  if (!webToken) {
    return <></>;
  }


  return (
    <VideoCallContext.Provider value={VideoCallContextValue}>
      <div className="d-flex" id="conference-page-wrapper">
        <Alert
          show={isCountdownRunning}
          key="warning"
          variant="warning"
          className="site-alert my-1"
        >
          Waiting for another participant. Call will auto end within{' '}
          {countdownSeconds} seconds.
        </Alert>
        <div id="conference-wrapper">
          {appData.callStatus?.current ===
          CallStatusType.PreparingCallSummary ? (
            <div className="col-12 text-center text-white p-3">
              <h3 className="m-0">Preparing Call Summary. Please wait...</h3>
            </div>
          ) : (
            <>
              <TopActionBar isOperatorRequired={isOperatorRequired} />
              {room && <Room callHangupHandler={callHangupHandler} />}
              <BottomActionBar
                setShowHangupModal={setShowHangupModal}
                showChat={showChat}
                setShowChat={setShowChat}
                isOperatorRequired={isOperatorRequired}
                addParticipant={appData.addParticipant!}
                operatorId={operatorId}
                callOperator={callOperator}
                userRole={appData.userRole!}
              />
            </>
          )}
        </div>
        {!isVideoConferenceCall && appData.chatSid && (
          <Chat
            chatSid={appData.chatSid}
            showChat={showChat}
            setShowChat={setShowChat}
          />
        )}
        <ModalHangupCall
          handleShow={setShowHangupModal}
          onSubmit={callHangupHandler}
          show={showHangupModal}
          header={'Hangup Call'}
        />
        <ModalVideoConferenceLink
          show={isVideoConferenceCall}
          roomId={roomId}
        />
      </div>
    </VideoCallContext.Provider>
  );
}
