// import { VideoRoomMonitor } from '@twilio/video-room-monitor';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Video, {
  Participant,
  RemoteParticipant,
  Room
} from 'twilio-video';
import {
  AppConstants,
  AppRouteUi,
  CallStatusType,
  CallType,
  VideoViewType
} from '../../configs';
import {
  ActionUi,
  ActionVideoCall,
  ChannelContext,
  StorageAuth,
  useAppSelector,
  VRIAppStateType
} from '../../core';
import { twilioParticipantHelper } from '../../helpers';
import { IParticipantsInfo, ISelectedParticipantInfo } from '../../models';
import { logger } from '../../utils';
import { useCountdown, useFunctionalityCalls } from '../hooks';

const { stopAllTrack } = twilioParticipantHelper();

interface IUseVideoCallProps {
  roomId: string;
  callType: CallType.Video | CallType.VideoConference;
}

export function useGuestCall({
  callType,
  roomId,
}: IUseVideoCallProps) {
  const [room, setRoom] = useState<Room | null>(null);
  //WHO IS SETTING THIS AND WHAT IS IT USED FOR?
  const [remoteParticipants, setRemoteParticipants] = useState<RemoteParticipant[]>([]);
  const [showChat, setShowChat] = useState(false);
  const [viewType, setViewType] = useState(VideoViewType.GridView);
  const [showHangupModal, setShowHangupModal] = useState(false);
  const [isSharing, setIsSharing] = useState(false);
  const [isVideoEnabled, setIsVideoEnabled] = useState(true);
  const [isAudioEnabled, setIsAudioEnabled] = useState(true);
  const [participantsInfo, setParticipantsInfo] = useState(
    {} as IParticipantsInfo
  );
  const [selectedParticipantInfo, setSelectedParticipantInfo] =
    useState<ISelectedParticipantInfo | null>(null);

  const appData = useAppSelector((state: VRIAppStateType) => ({
    VRIToken: state.auth.VRItoken,
    callStatus: state.videoCall.CallStatus,
      // userRole: state.auth.profile?.userRole,
    // addParticipant: state.auth.menuSettings?.callSettings?.addParticipant,
    // callerId: state.ui.callerId,
    // receiverId: state.ui.receiverId,
    // chatSid: state.ui.chatSid,
    // twilioDeviceStatus: state.common,
    // callSummaryInfo: state.videoCall.callSummary,
    tracks: state.videoCall.tracks,
  }));
  const { setVideoChatMessageCount } = useContext(ChannelContext);
  const { onUpdate } = useFunctionalityCalls();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    seconds: countdownSeconds,
    isRunning: isCountdownRunning,
    startCountdown,
    isFinished: isCountdownFinished,
    endCountdown,
  } = useCountdown();
  const havePendingCountdownRef = useRef(false);
  const haveOngoingCallRef = useRef(false);

  // const homePath = useMemo(() => {
  //   if (appData.userRole === AppConstants.UserRoles.Operator) {
  //     return AppRouteUi.CallLog.Root;
  //   } else if (appData.userRole === AppConstants.UserRoles.Consumer) {
  //     return AppRouteUi.Consumer.Root;
  //   } else {
  //     return AppRouteUi.Home.Root;
  //   }
  // }, [appData.userRole]);

  const homePath = AppRouteUi.GuestCallEnded.Root;

  const resetCallStatus = useCallback(() => {
    // dispatch(ActionUi.SetCallerId(''));
    dispatch(ActionUi.SetRoomId(''));
    // dispatch(ActionUi.SetReceiverId(''));
    dispatch(ActionUi.SetChatSid(''));
    dispatch(ActionUi.SetReceiverDeviceId(''));
    dispatch(ActionUi.SetLanguageName(''));
    dispatch(ActionUi.SetCallerDeviceId(''));
    dispatch(ActionUi.SetCallType(CallType.None));
    setVideoChatMessageCount(0);
    setShowHangupModal(false);
    dispatch(ActionVideoCall.setOperatorStatus(false));
  }, [dispatch, setVideoChatMessageCount]);

  // const getRedirectionPath = useCallback(() => {
  //   if (appData.userRole === AppConstants.UserRoles.Consumer) {
  //     return AppRouteUi.VideoConference.Rejoin.Load(roomId);
  //   } else if (appData.userRole === AppConstants.UserRoles.Operator) {
  //     return AppRouteUi.CallLog.Root;
  //   } else {
  //     return AppRouteUi.Home.Root;
  //   }
  // }, [appData.userRole, roomId]);

  console.log("console logging from useGuestCall. The VRIToken is: " + appData.VRIToken);


  //start---------------------------------------------------------------------------------
  const callHangupHandler = useCallback(() => {
    logger({ message: '!!!!!!!!!!!!!!!!!!!!![Room]: Hanging Call!!!!!!!!!!!!!!!!!!!' });

    stopAllTrack(room!);
    //this does not trigger the room itself to be closed, it only diconnects the current participant from it.
    room?.disconnect();
    resetCallStatus();
    dispatch(
      ActionVideoCall.SetCallStatus({
        prev: CallStatusType.Established,
        current: CallStatusType.Completed,
      })
    );

  }, [
    appData,
    dispatch,
    history,
    //   isOperatorRequired,
    // isVideoConferenceCall,
    room,
    roomId,
    resetCallStatus,
    //   getRedirectionPath,
  ]);

  //end---------------------------------------------------------------------------------


  const handleParticipantClick = useCallback(
    (participant: Participant, type: 'Remote' | 'Local') => {
      setSelectedParticipantInfo({ participant, type });
    },
    []
  );

  const connectToRoom = useCallback(
    async ({ token, roomId }: { token: string; roomId: string }) => {
      logger({ message: 'TTT function connectToRoom inside the useGuestCall file has been invoked' });
      console.log("TTT the token is: " + token);
      // console.log("TTT the twiliodevicestatus is:");
      // console.log(appData.twilioDeviceStatus);
      console.log("The tracks are: ");
      console.log(appData.tracks);




      try {
        logger({ message: '[Room]: Creating Room' });
        const room = await Video.connect(token, {
          name: roomId,
          networkQuality: { local: 1, remote: 1 },
          tracks: appData.tracks!,
        });
        // VideoRoomMonitor.registerVideoRoom(room);
        logger({ message: `[Room]: Call Id: ${roomId}` });
        logger({ message: `[Room]: Call sid: ${room.sid}` });
        setRoom(room);
        console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ ROOM $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$");
        console.log(room);
      } catch (error) {
        logger({ message: '[Room]: Failed To Create Room: ' + error, data: error });
        toast.error('An error have occurred during joining');
        //console.trace("Redirecting:");
        
        history.push(homePath);
      }
    },
    //   [history, homePath]
    [history]
  );

  useEffect(() => {
    console.log('--------------useEffect 1 triggered');
    if (
      (appData.callStatus?.current === CallStatusType.Completed) ||
      (appData.callStatus?.current === CallStatusType.Zero)
    ) {
      console.log("useEffect 1 condition satisfied, callstatus is in state completed or state Zero");

      logger({ message: '[Room]: Disconnecting Room' });
      if (room) {
        stopAllTrack(room!);
        room?.disconnect();
      }
      resetCallStatus();
      //console.trace("Redirecting:");
      history.push(homePath);
    }
    // }, [appData, history, room, resetCallStatus, homePath]);
  }, [appData.callStatus, history, room, resetCallStatus, homePath]);


  useEffect(() => {
    console.log('--------------useEffect 2 triggered');
    // console.log("appData.callStatus?.current: " + appData.callStatus?.current);
    // console.log("CallStatusType.PreparingCallSummary: "+ CallStatusType.PreparingCallSummary);
    // console.log("appData.callSummaryInfo.roomId: " + appData.callSummaryInfo.roomId);
    // console.log("roomId: "+ roomId);

    if (
      appData.callStatus?.current === CallStatusType.PreparingCallSummary 
      // && appData.callSummaryInfo.roomId === roomId
    ) {
      console.log("useEffect 2 condition satisfied: invoking callHangupHandler because current call status is PreparingCallSummary && callSummaryInfo.roomId === roomId");
      callHangupHandler();
    }
    // }, [appData.callStatus, appData.callSummaryInfo, callHangupHandler, roomId]);
  }, [appData.callStatus, callHangupHandler, roomId]);


  useEffect(() => {
    console.log('--------------useEffect 3 triggered');
    if (appData.VRIToken && roomId && !haveOngoingCallRef.current) {
      console.log("useEffect 3 condition satisfied, we are about to connect to a Room");
      connectToRoom({ roomId: roomId, token: appData.VRIToken });
      haveOngoingCallRef.current = true;
    }
  }, [roomId, appData.VRIToken, connectToRoom]);


  useEffect(() => {
    console.log('--------------useEffect 4 triggered');
    if (remoteParticipants) {
      console.log('--------------useEffect 4 condition satisfied - remoteParticipants is defined');
      try {
        //console.trace("Redirecting:");
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function () {
          //console.trace("Redirecting:");
          window.history.pushState(null, document.title, window.location.href);
        });
      } catch (err) {
        console.error(err);
      }
    }
  }, [remoteParticipants]);

  useEffect(() => {
    console.log('--------------useEffect 5 triggered; no condition. Trigger: call status have changed to: ', appData.callStatus);
    logger({ message: 'Call Status: ', data: appData.callStatus });
  }, [appData.callStatus]);

  useEffect(() => {
    console.log('--------------useEffect 6 triggered');
    // if (
    //   isVideoConferenceCall
    //   && appData.userRole !== AppConstants.UserRoles.Operator
    // ) {
    //   console.log('--------------useEffect 6 condition 1 is satisfied, the call is a videoconference. returning -');
    //   return;
    // }
    let timeoutId: number | null = null;

    if (!room?.participants.size && !havePendingCountdownRef.current) {
      console.log('--------------useEffect 6 condition 2 is satisfied, there are no participants and something else -');

      havePendingCountdownRef.current = true;
      logger({ message: '[Room]: Starting pending auto call end countdown' });
      timeoutId = window.setTimeout(() => {
        if (room?.participants.size) {
          clearTimeout(timeoutId!);
          logger({
            message: '[Room]: Clearing pending auto call end countdown',
          });
          havePendingCountdownRef.current = false;
        } else {
          logger({ message: '[Room]: Starting auto call end countdown' });
          startCountdown(60);
        }
      }, 5000);
    }
    if (
      room?.participants.size &&
      havePendingCountdownRef.current &&
      isCountdownRunning
    ) {
      console.log('--------------useEffect 6 condition 3 is satisfied, there is a countdown running -');

      endCountdown();
      logger({ message: '[Room]: Clearing auto call end countdown' });
      havePendingCountdownRef.current = false;
    }
  }, [
    startCountdown,
    isCountdownRunning,
    room?.participants.size,
    endCountdown,
  ]);

  useEffect(() => {
    console.log('--------------useEffect 7 triggered');
    if (isCountdownFinished) {
      logger({
        message: '[Room]: Another participant not found. Ending call.',
      });
      console.log("useEffect 7 condition satisfied, invoking callHangupHandler because Another participant not found and countdown has finished. Ending call");
      callHangupHandler();
    }
  }, [isCountdownFinished, callHangupHandler]);

  const VideoGuestContextValue = {
    room,
    //   userRole: appData?.userRole!,
    viewType,
    setViewType,
    callType,
    remoteParticipants,
    participantsInfo,
    selectedParticipantInfo,
    setRemoteParticipants,
    setIsSharing,
    isSharing,
    isVideoEnabled,
    isAudioEnabled,
    setIsVideoEnabled,
    setIsAudioEnabled,
    setParticipantsInfo,
    setSelectedParticipantInfo,
    handleParticipantClick,
  };

  return {
    appData,
    room,
    showChat,
    setShowChat,
    showHangupModal,
    VideoGuestContextValue,
    callHangupHandler,
    setShowHangupModal,
    //   callOperator,
    selectedParticipantInfo,
    setSelectedParticipantInfo,
    countdownSeconds,
    isCountdownRunning,
  };
}
