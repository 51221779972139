import React, { useEffect } from 'react';
import { AppRouteUi, PlatformType, SubscriptionType, topLoaderProgress } from '../../../configs';
import { ApiSchemaCompanyByIdData } from '../../../models';
import { ModalBossSystemFees, ModalContractDocumentUpload, ModalContractPlatforms, ModalLateFee, ModalSubscriptionUpdate } from '../../modals/common';
import { CardBossSystem, CardContractDocuments, CardLate, CardOnSite, CardOpi, CardPostPaid, CardVri } from './contractTabCards';
import { useState } from 'react';
import { useFunctionalityCompanyContractDetails, useFunctionalityLanguagePack } from "../../../pages/hooks";
import { useAppSelector,  VRIAppStateType} from "../../../core";
import { shallowEqual } from "react-redux";
import LoadingBar from "react-top-loading-bar";
import { NoDataComponent } from '../../admin/noDataComponent';
import { useLocation } from 'react-router-dom';

type Props = {
	//	companyProfileData?: ApiSchemaCompanyByIdData;
	editable: boolean;
};

export function ContractTab({ editable }: Props) {

	const [subscriptionModalShow, setSubscriptionModalShow] = useState(false);
	const [bossFeeModalShow, setBossFeeModalShow] = useState(false);
	const [contractPlatformModalShow, setContractPlatformModalShow] = useState(false);
	const [contractPlatformType, setContractPlatformType] = useState<PlatformType>(0)
	const [lateFeeModalShow, setLateFeeModalShow] = useState(false);
	const [documentUploadModalShow, setDocumentUploadModalShow] = useState(false);
	const [onUpdateSuccess, setOnUpdateSuccess] = useState(false);
	const [loaderProgress, setLoaderProgress] = useState(0);

	let companyUrl = window.location.href.split("/")
	let companyId = companyUrl[companyUrl.length - 1]

	// let resellerId = AppRouteUi.Reseller.Profile.useParam();
	let location = useLocation<any>();
	let parentCompanyId = location?.state?.parentCompanyId
	const { onCompanyContractDetails } = useFunctionalityCompanyContractDetails({
		companyId: companyId,
		setLoaderProgress: setLoaderProgress
	});

	const [companyContractDetails, setCompanyContractDetails] = useState<ApiSchemaCompanyByIdData>();

	useEffect(() => {
		onCompanyContractDetails()
			?.then((res) => {
				setCompanyContractDetails(res?.data);
				setLoaderProgress(topLoaderProgress.complete);
			}
			)
	}, [onUpdateSuccess]);

	const getVriCardData = () => {
		return companyContractDetails?.contract?.contactPlatforms?.find(x => x.platform === PlatformType.VRI) ?? null
	}

	const getOpiCardData = () => {
		return companyContractDetails?.contract?.contactPlatforms?.find(x => x.platform === PlatformType.OPI) ?? null;
	}

	const getOnSiteCardData = () => {
		return companyContractDetails?.contract?.contactPlatforms?.find(x => x.platform === PlatformType.OnSite) ?? null;
	}

	const getBossCardData = () => {
		return companyContractDetails?.contract?.bossContract;
	}

	const getContractDocumentsCardData = () => {
		return companyContractDetails?.contract?.contractDocuments ?? null;
	}

	const getContractInfo = () => {
		return companyContractDetails?.contract?.contactPlatforms?.find(x => x.platform === contractPlatformType);
	}

	const getVriContractPlatform = () => {
		return companyContractDetails?.contract?.contactPlatforms?.find(x => x.platform === PlatformType.VRI);
	}

	const getOpiContractPlatform = () => {
		return companyContractDetails?.contract?.contactPlatforms?.find(x => x.platform === PlatformType.OPI);
	}

	const getOnsiteContractPlatform = () => {
		return companyContractDetails?.contract?.contactPlatforms?.find(x => x.platform === PlatformType.OnSite);
	}

	const getBossSystemModalData = () => {
		return companyContractDetails?.contract?.bossContract;
	}

	const currentUserInfo = useAppSelector(
		(state: VRIAppStateType) => ({
			companyId: state.auth.profile?.companyId || "",
		}),
		shallowEqual
	);

	const { languagePackList } = useFunctionalityLanguagePack({ companyId:parentCompanyId ?? currentUserInfo?.companyId, shouldLanguagePackGetAllInvoke: contractPlatformModalShow });

	return (
		<>
			{companyContractDetails ? (
				<></>
			) : (
				<LoadingBar
					progress={loaderProgress}
					onLoaderFinished={() => setLoaderProgress(0)}
				/>
			)}
			{
				companyContractDetails ?
					<div className="row mt-3">
						<div className="col-12 col-sm-12 col-md-12 col-lg-6">
							{
								companyContractDetails?.subscription?.type === SubscriptionType.Prepaid ? (
									<CardPostPaid
										handleShow={setSubscriptionModalShow}
										data={companyContractDetails?.subscription}
										editable={editable}
									/>
								) : companyContractDetails?.subscription?.type === SubscriptionType.Postpaid ? (
									<CardPostPaid
										handleShow={setSubscriptionModalShow}
										data={companyContractDetails?.subscription}
										isReseller={companyContractDetails.isReseller}
										editable={editable}
									/>
								) : null
							}
							<CardContractDocuments
								id={companyContractDetails?.id ?? ''}
								editable={editable}
								handleShow={setDocumentUploadModalShow}
								data={getContractDocumentsCardData()}
								onUpdateSuccess={onUpdateSuccess}
								setOnUpdateSuccess={setOnUpdateSuccess}
							/>
						</div>
						<div className="col-12 col-sm-12 col-md-12 col-lg-6">
							<CardBossSystem
								editable={editable}
								handleShow={setBossFeeModalShow}
								data={getBossCardData()}
							/>
							<CardVri
								editable={editable}
								handleShow={setContractPlatformModalShow}
								setPlatformType={setContractPlatformType}
								data={getVriCardData()}
							/>
							<CardOpi
								editable={editable}
								handleShow={setContractPlatformModalShow}
								setPlatformType={setContractPlatformType}
								data={getOpiCardData()}
							/>
							<CardOnSite
								editable={editable}
								handleShow={setContractPlatformModalShow}
								setPlatformType={setContractPlatformType}
								data={getOnSiteCardData()}
							/>
							<CardLate
								editable={editable}
								handleShow={setLateFeeModalShow}
								data={companyContractDetails?.subscription}
							/>

						</div>

						{
							subscriptionModalShow && <ModalSubscriptionUpdate
								id={companyContractDetails?.id ?? ''}
								show={subscriptionModalShow}
								handleShow={setSubscriptionModalShow}
								subscriptionType={companyContractDetails?.subscription?.type ?? 0}
								subscriptionInfo={companyContractDetails?.subscription}
								onUpdateSuccess={onUpdateSuccess}
								setOnUpdateSuccess={setOnUpdateSuccess}
								isReseller={companyContractDetails.isReseller}
							/>
						}
						{
							bossFeeModalShow && <ModalBossSystemFees
								id={companyContractDetails?.id ?? ''}
								show={bossFeeModalShow}
								handleShow={setBossFeeModalShow}
								header="Update Boss System"
								data={getBossSystemModalData()}
								contractId={companyContractDetails?.contract?.id ?? ''}
								setOnUpdateSuccess={setOnUpdateSuccess}
							/>
						}

						{
							contractPlatformModalShow ?
								<ModalContractPlatforms
									id={companyContractDetails?.id ?? ''}
									show={contractPlatformModalShow}
									handleShow={setContractPlatformModalShow}
									contractId={companyContractDetails?.contract?.id ?? ''}
									onUpdateSuccess={onUpdateSuccess}
									setOnUpdateSuccess={setOnUpdateSuccess}
									vriContractPlatforms={getVriContractPlatform()}
									opiContractPlatforms={getOpiContractPlatform()}
									onSiteContractPlatforms={getOnsiteContractPlatform()}
									contractInfo={getContractInfo()}
									contractType={contractPlatformType}
									languagePackList={languagePackList}
								/>
								: null
						}
						{
							lateFeeModalShow && <ModalLateFee
								id={companyContractDetails?.id ?? ''}
								show={lateFeeModalShow}
								handleShow={setLateFeeModalShow}
								data={companyContractDetails?.subscription}
								onUpdateSuccess={onUpdateSuccess}
								setOnUpdateSuccess={setOnUpdateSuccess}
							/>
						}
						{
							documentUploadModalShow && <ModalContractDocumentUpload
								id={companyContractDetails?.id ?? ''}
								show={documentUploadModalShow}
								handleShow={setDocumentUploadModalShow}
								contractId={companyContractDetails?.contract?.id ?? ''}
								onUpdateSuccess={onUpdateSuccess}
								setOnUpdateSuccess={setOnUpdateSuccess}
							/>
						}
					</div>
					:
					<NoDataComponent title="Loading..." />
			}
		</>
	)
}
