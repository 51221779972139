import React, { useState } from 'react';
import { TypeContractDocument } from "../../../../models";
import { ModalConfirmDelete } from "../../../modals";
import moment from 'moment';
import { useFunctionalityCompanyContract } from "../../../../pages/hooks";
import { AppConstants, FileBucketURL } from "../../../../configs";
import { useAppSelector, VRIAppStateType } from "../../../../core";
import { AssetSvg } from '../../../../assets';

type Props = {
  id: string,
  handleShow: (value: boolean) => void,
  data: TypeContractDocument[] | null,
  editable: boolean;
  onUpdateSuccess?: any,
  setOnUpdateSuccess?: any,
}

export function CardContractDocuments(props: Props): JSX.Element {
  const { id, handleShow, data, editable, onUpdateSuccess, setOnUpdateSuccess } = props;
  const [dataToDelete, setDataToDelete] = useState<string>('');
  const userRole = useAppSelector((state: VRIAppStateType) => state.auth.profile?.userRole);

  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const { onDeleteContract } = useFunctionalityCompanyContract();

  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const getDocumentTime = (time: string) => {
    const date = new Date(time);

    if (Object.prototype.toString.call(date) === "[object Date]") {
      if (isNaN(date.getTime())) {
        return "";
      }
    } else {
      return "";
    }

    const modifiedDate = monthNames[date.getMonth()].concat(' ' + date.getDate().toString()).concat(', ' + date.getFullYear().toString());
    return modifiedDate ?? "";
  }

  const onDeleteHandler = () => {
    onDeleteContract(id, dataToDelete)
      .then(() => {
        setDeleteModalShow(false);
        setOnUpdateSuccess(!onUpdateSuccess);
      })
      .catch((e) => console.error(e));
  };

  return (
    <>
      <div className="component-card mb-3">
        <div className="component-card-header d-flex align-items-center justify-content-between border-top border-right border-left">
          <div>Contract Documents</div>
          {editable ? (
            <button className="btn btn-link px-0 text-site btn-sm text-uppercase" onClick={() => handleShow(true)}>
              <div className="btn svg-color" title="contractDocumentUpload">
                <svg width="512" height="512" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_132_5)">
                    <path d="M234.816 54.9973L234.667 384.341C234.667 389.999 236.914 395.425 240.915 399.426C244.916 403.427 250.342 405.675 256 405.675C261.658 405.675 267.084 403.427 271.085 399.426C275.086 395.425 277.333 389.999 277.333 384.341L277.483 55.36L339.605 117.504C343.606 121.503 349.031 123.75 354.688 123.75C360.345 123.75 365.77 121.503 369.771 117.504V117.504C373.77 113.503 376.017 108.078 376.017 102.421C376.017 96.7645 373.77 91.3392 369.771 87.3386L301.269 18.752C295.326 12.8047 288.269 8.08681 280.501 4.86794C272.734 1.64908 264.408 -0.00769043 256 -0.00769043C247.592 -0.00769043 239.266 1.64908 231.499 4.86794C223.731 8.08681 216.674 12.8047 210.731 18.752L142.229 87.2747C138.23 91.2752 135.983 96.7005 135.983 102.357C135.983 108.014 138.23 113.439 142.229 117.44V117.44C146.23 121.439 151.655 123.686 157.312 123.686C162.969 123.686 168.394 121.439 172.395 117.44L234.816 54.9973Z" />
                    <path d="M469.333 362.667V448C469.333 453.658 467.086 459.084 463.085 463.085C459.084 467.086 453.658 469.333 448 469.333H64C58.342 469.333 52.9158 467.086 48.9151 463.085C44.9143 459.084 42.6667 453.658 42.6667 448V362.667C42.6667 357.009 40.4191 351.582 36.4183 347.582C32.4175 343.581 26.9913 341.333 21.3333 341.333V341.333C15.6754 341.333 10.2492 343.581 6.24839 347.582C2.24761 351.582 0 357.009 0 362.667L0 448C0 464.974 6.74284 481.253 18.7452 493.255C30.7475 505.257 47.0261 512 64 512H448C464.974 512 481.253 505.257 493.255 493.255C505.257 481.253 512 464.974 512 448V362.667C512 357.009 509.752 351.582 505.752 347.582C501.751 343.581 496.325 341.333 490.667 341.333C485.009 341.333 479.582 343.581 475.582 347.582C471.581 351.582 469.333 357.009 469.333 362.667Z" />
                  </g>
                  <defs>
                    <clipPath id="clip0_132_5">
                      <rect width="512" height="512" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>

            </button>
          ) : (<></>)
          }
        </div>
        <div className="component-card-body border">
          {
            data ? (
              <>
                {
                  data?.map(file => (
                    <div className="card mb-1">
                      <div className="card-body">
                        <div className="row">
                          <div className="col col-card-details">
                            <p className="mb-0 text-muted mb-1">
                              {getDocumentTime(moment(file?.uploadDate).format('MM-DD-YYYY'))}
                            </p>
                            <p className="text-dark font-500 mb-0">
                              {file.name}
                            </p>
                          </div>

                          <div className="col col-button-container pr-0">
                            <a href={`${FileBucketURL.Root}${file?.id}`} target="_blank" className="btn btn-link  text-site px-2 font-sz-12 mr-1" role="button">
                              <div className="svg-color" title="contractDocumentDownload">
                                <span>
                                  <AssetSvg.Download />
                                </span>
                              </div>
                            </a>

                            {
                              (userRole === AppConstants.UserRoles.Admin) &&
                              <a className="btn btn-link  text-site px-3 font-sz-12">
                                <div className="svg-color" title="contractDocumentDelete" onClick={() => {
                                  setDataToDelete(file.id)
                                  setDeleteModalShow(true)
                                }
                                }>
                                  <span>
                                    <AssetSvg.Delete />
                                  </span>
                                </div>
                              </a>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </>
            ) : (
              <p className="mb-0 text-dark py-3">
                No document found
              </p>
            )
          }
        </div>
      </div>

      <ModalConfirmDelete show={deleteModalShow}
        handleShow={setDeleteModalShow}
        deleteHandler={onDeleteHandler} />
    </>
  )
}