import { faReceipt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { MouseEventHandler, MutableRefObject } from "react";
import { shallowEqual } from "react-redux";
import { Link, useLocation } from 'react-router-dom';
import { AssetSvg } from "../../assets";
import { AppConstants, AppRouteUi, DefaultImageType, FileBucketURL } from "../../configs";
import { SocketContext, useAppSelector, VRIAppStateType} from "../../core";
import { AccessControlTemplate, AccessList } from "../../helpers";
import { useFunctionalityAuth } from "../../pages/hooks";
import { useFunctionalityImageView } from "../../pages/hooks/functionalityImageView";


import { UserAgentUtils } from "../../utils";

type accessRoles = keyof typeof AccessList;
type accessPages = keyof typeof AccessControlTemplate;

type Props = {
  isToggled: boolean;
  isSubMenuClickedRef?: MutableRefObject<boolean>;
};

export function SideNav({ isToggled, isSubMenuClickedRef }: Props) {
  const location = useLocation();

  const userRole = useAppSelector(
    (state: VRIAppStateType) => state.auth.profile?.userRole,
    shallowEqual
  );
  const userId = useAppSelector(
    (state: VRIAppStateType) => state.auth.profile?.id,
    shallowEqual
  );
  const userCompany = useAppSelector(
    (state: VRIAppStateType) => state.auth.profile?.companyId,
    shallowEqual
  );
  const menuSettings = useAppSelector((state: VRIAppStateType) => state.auth.menuSettings);

  const AccessControl: typeof AccessControlTemplate = JSON.parse(
    JSON.stringify(AccessControlTemplate)
  );

  for (let i = 0; i < AccessList[userRole as accessRoles]?.length; i++) {
    AccessControl[AccessList[userRole as accessRoles][i] as accessPages] = true;
  }

  const toggleClass = () => {
    if (isToggled) {
      return "sidebar-toggle";
    } else {
      return "";
    }
  };

  const toggleActiveTab = React.useCallback(
    (route: string) =>
      location.pathname === route ? "nav-link active" : "nav-link",
    [location.pathname]
  );
  const { onLogout } = useFunctionalityAuth()
  const { defaultImageLoad, imageView } = useFunctionalityImageView()

  const handleSubMenuClick = () => {
    if (isSubMenuClickedRef) {
      isSubMenuClickedRef.current = true;
    }
  };

  return (
    <div className={toggleClass()} id="sidebar-wrapper">
      <div className="sidebar-heading">
        <div className="logo-container">
          <a href="">
            <img
              src={`${FileBucketURL.Root}${userCompany}?${imageView.fileTimeStamp}`}
              alt="logo"
              onError={(ev) => {

                if (userRole === AppConstants.UserRoles.Admin) {
                  defaultImageLoad(ev, DefaultImageType.CompanyProfile, "?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260")
                }
                else {
                  defaultImageLoad(ev, DefaultImageType.DefaultCompanyProfile, "?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260")
                }

              }}
              className="img-fluid side-profile-img"
            />

          </a>
        </div>
      </div>
      <div className="list-group list-group-flush sidebar-menu-height">
        {AccessControl.Call ? (

          <div
            className={
              location.pathname === AppRouteUi.Home.Root
                ? "activeSinglenav"
                : ""
            }
          >
            <Link
              to={AppRouteUi.Home.Root}
              className="list-group-item list-group-item-action collapsed"
            >
              <div className="d-flex align-items-center">

                <svg version="1.1" id="Layer_1" x="0px" y="0px"
                  viewBox="0 0 13.9 13.9"  >

                  <g id="Layer_2_00000074442811619430699910000014703507781227538566_">
                    <g id="Layer_1-2">
                      <path fill="currentColor" d="M0,2.6c0-1,0.6-1.9,1.5-2.3C1.8,0.1,2.1,0,2.5,0c0.4,0,0.7,0.1,1,0.3c0.4,0.4,0.8,0.9,1.1,1.4
			c0.3,0.5,0.6,0.9,0.9,1.4C5.8,3.7,5.6,4.4,5,4.7L4.7,4.9C4.3,5.2,4.2,5.7,4.4,6.1c0.2,0.6,0.6,1.2,1,1.7C6,8.5,6.8,9,7.6,9.4
			c0.2,0.2,0.6,0.2,0.8,0.1c0.2-0.1,0.3-0.3,0.4-0.4C9,8.8,9.3,8.6,9.6,8.4c0.4-0.2,0.9-0.2,1.2,0.1c0.4,0.2,0.8,0.5,1.2,0.7
			c0.4,0.3,0.9,0.6,1.3,1c0.6,0.4,0.8,1.1,0.4,1.7c-0.4,1-1.3,1.7-2.3,2c-0.4,0.1-0.9,0-1.3-0.2C8.4,13,6.9,12.1,5.5,11
			c-1.2-1-2.3-2.1-3.2-3.4C1.5,6.4,0.8,5.2,0.3,3.8C0.1,3.4,0,3,0,2.6z"/>
                    </g>
                  </g>
                </svg>
                <div className="sideNav-item mb-0">Call</div>
              </div>
            </Link>
          </div>
        ) : null}

        {menuSettings?.callSettings?.scheduleJobEnabled ? (
          <>
            <a
              className="list-group-item list-group-item-action  collapsed"
              data-toggle="collapse"
              href="#call-sidebar-menu"
              role="button"
              aria-expanded="false"
              aria-controls="call-sidebar-menu"
              onClick={handleSubMenuClick}
            >
              <div className="d-flex align-items-center">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 14.13 14.13">
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Layer_1-2" data-name="Layer 1">
                        <path fill="currentColor" d="M7.06,0A7.07,7.07,0,1,1,0,7.07,7.07,7.07,0,0,1,7.06,0ZM8.12,6.69c0-.88,0-1.71,0-2.54a1.15,1.15,0,1,0-2.28,0V7.71A1.16,1.16,0,0,0,7.08,9h3.57a1.16,1.16,0,0,0,1.27-1.13,1.17,1.17,0,0,0-1.28-1.15Z" />
                      </g>
                    </g>
                  </svg>

                </span>
                <div className="sideNav-item mb-0 ">Scheduled Jobs</div>
              </div>
            </a>
            <ul
              className="nav  flex-column collapse collapsable-sidemenu-item flex-nowrap collapsibleMenuHighlighter"
              id="call-sidebar-menu"
            >
              <li className=" nav-item sub-menu-item">
                <Link
                  to={AppRouteUi.Jobs.Root}
                  className={toggleActiveTab(AppRouteUi.Jobs.Root)}
                >
                  Jobs
                </Link>
              </li>
              <li className="nav-item sub-menu-item">
                <Link
                  to={AppRouteUi.Calendar.Root}
                  className={toggleActiveTab(AppRouteUi.Calendar.Root)}
                >
                  Calendar
                </Link>
              </li>
              {/* <li className="nav-item sub-menu-item">
                <Link
                  to={AppRouteUi.VideoScheduler.Root}
                  className={toggleActiveTab(AppRouteUi.VideoScheduler.Root)}
                >
                  Video Schedules
                </Link>
              </li> */}
              {/* {AccessControl.Job_Settings ? (
                <li className=" nav-item sub-menu-item">
                  <Link
                    to={AppRouteUi.Settings.Root}
                    className={toggleActiveTab(AppRouteUi.Settings.Root)}
                  >
                    Settings
                  </Link>
                </li>
              ) : null} */}
            </ul>
          </>
        ) : null}

        {AccessControl.Internal_CallLog ? (
          <>
            <a
              className="list-group-item list-group-item-action collapsed"
              data-toggle="collapse"
              href="#callLog-sidebar-menu"
              role="button"
              aria-expanded="false"
              aria-controls="callLog-sidebar-menu"
              onClick={handleSubMenuClick}
            >
              <div className="d-flex align-items-center">
                <span>
                  <svg viewBox="0 0 15.24 15.97">
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Layer_1-2" data-name="Layer 1">
                        <path fill="currentColor" d="M11.73,0A3.52,3.52,0,1,1,8.21,3.52,3.52,3.52,0,0,1,11.73,0Zm.52,3.33V2.06a.57.57,0,1,0-1.14,0V3.84a.57.57,0,0,0,.62.62h1.78a.57.57,0,0,0,.63-.56.58.58,0,0,0-.64-.57Z" />
                        <path fill="currentColor" d="M0,4.38a2.53,2.53,0,0,1,1.48-2.3,2.46,2.46,0,0,1,1-.32,1.33,1.33,0,0,1,1,.31,7.44,7.44,0,0,1,1.23,1.4,16.62,16.62,0,0,1,.88,1.46,1.24,1.24,0,0,1-.45,1.66l-.27.2A1,1,0,0,0,4.48,8a5.72,5.72,0,0,0,1,1.7,6.46,6.46,0,0,0,2.24,1.64.87.87,0,0,0,.85.07A1.76,1.76,0,0,0,9,11a2,2,0,0,1,.76-.64,1.38,1.38,0,0,1,1.25,0,11.68,11.68,0,0,1,1.21.76q.66.46,1.29,1A1.34,1.34,0,0,1,14,13.91a3.5,3.5,0,0,1-2.35,2,1.87,1.87,0,0,1-1.33-.19,19.73,19.73,0,0,1-4.67-2.79A17.18,17.18,0,0,1,2.4,9.51,19,19,0,0,1,.28,5.66,3.31,3.31,0,0,1,0,4.38Z" />
                      </g>
                    </g>
                  </svg>
                </span>

                <div className="sideNav-item mb-0 ">Call Log</div>
              </div>
            </a>
            <ul
              className="nav  flex-column collapse collapsable-sidemenu-item flex-nowrap collapsibleMenuHighlighter"
              id="callLog-sidebar-menu"
            >
              <li className="nav-item sub-menu-item">
                <Link
                  className={toggleActiveTab(AppRouteUi.InternalCallLog.Root)}
                  to={AppRouteUi.InternalCallLog.Root}
                >
                  Internal Call
                </Link>
              </li>

              {AccessControl.Inflow_CallLog ? (
                <>
                  <li className="nav-item sub-menu-item">
                    <Link
                      to={AppRouteUi.InflowCallLog.Root}
                      className={toggleActiveTab(AppRouteUi.InflowCallLog.Root)}
                    >
                      Inflow Call
                    </Link>
                  </li>
                </>
              ) : null}
              {AccessControl.Outflow_CallLog ? (
                <>
                  <li className="nav-item sub-menu-item">
                    <Link
                      to={AppRouteUi.OutflowCallLog.Root}
                      className={toggleActiveTab(
                        AppRouteUi.OutflowCallLog.Root
                      )}
                    >
                      Outflow Call
                    </Link>
                  </li>
                </>
              ) : null}
              {AccessControl.Overflow_CallLog ? (
                <>
                  <li className="nav-item sub-menu-item">
                    <Link
                      to={AppRouteUi.OverflowCallLog.Root}
                      className={toggleActiveTab(
                        AppRouteUi.OverflowCallLog.Root
                      )}
                    >
                      Overflow Call
                    </Link>
                  </li>
                </>
              ) : null}
            </ul>
          </>
        ) : AccessControl.Call_Log ? (
          <div
            className={
              location.pathname === AppRouteUi.CallLog.Root
                ? "activeSinglenav"
                : ""
            }
          >
            <Link
              to={AppRouteUi.CallLog.Root}
              className="list-group-item list-group-item-action collapsed"
            >
              <div className="d-flex align-items-center">
                <svg viewBox="0 0 15.24 15.97">
                  <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                      <path fill="currentColor" d="M11.73,0A3.52,3.52,0,1,1,8.21,3.52,3.52,3.52,0,0,1,11.73,0Zm.52,3.33V2.06a.57.57,0,1,0-1.14,0V3.84a.57.57,0,0,0,.62.62h1.78a.57.57,0,0,0,.63-.56.58.58,0,0,0-.64-.57Z" />
                      <path fill="currentColor" d="M0,4.38a2.53,2.53,0,0,1,1.48-2.3,2.46,2.46,0,0,1,1-.32,1.33,1.33,0,0,1,1,.31,7.44,7.44,0,0,1,1.23,1.4,16.62,16.62,0,0,1,.88,1.46,1.24,1.24,0,0,1-.45,1.66l-.27.2A1,1,0,0,0,4.48,8a5.72,5.72,0,0,0,1,1.7,6.46,6.46,0,0,0,2.24,1.64.87.87,0,0,0,.85.07A1.76,1.76,0,0,0,9,11a2,2,0,0,1,.76-.64,1.38,1.38,0,0,1,1.25,0,11.68,11.68,0,0,1,1.21.76q.66.46,1.29,1A1.34,1.34,0,0,1,14,13.91a3.5,3.5,0,0,1-2.35,2,1.87,1.87,0,0,1-1.33-.19,19.73,19.73,0,0,1-4.67-2.79A17.18,17.18,0,0,1,2.4,9.51,19,19,0,0,1,.28,5.66,3.31,3.31,0,0,1,0,4.38Z" />
                    </g>
                  </g>
                </svg>
                <div className="sideNav-item mb-0 ">Call Log</div>
              </div>
            </Link>
          </div>
        ) : null}

        <div
          className={
            location.pathname === AppRouteUi.Profile.Root
              ? "activeSinglenav"
              : ""
          }
        >
          <Link
            to={AppRouteUi.Profile.Root}
            className="list-group-item list-group-item-action collapsed"
          >
            <div className="d-flex align-items-center">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.29 13.75">
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <path fill="currentColor" d="M6.14,7.14A3.57,3.57,0,1,0,6.14,0,3.6,3.6,0,0,0,2.57,3.57,3.6,3.6,0,0,0,6.14,7.14Z" />
                    <path fill="currentColor" d="M5.79,13.75l-.65-.07a6.67,6.67,0,0,1-2.3-.74A7.15,7.15,0,0,1,.08,10.38a.44.44,0,0,1,0-.48,4.43,4.43,0,0,1,3-2.42l.33-.06A.68.68,0,0,1,4,7.56a3.7,3.7,0,0,0,2.37.68,3.31,3.31,0,0,0,1.81-.57,1.27,1.27,0,0,1,1.2-.16,4.53,4.53,0,0,1,2.75,2.11c.27.45.28.46,0,.9A7.09,7.09,0,0,1,9.26,13a6.85,6.85,0,0,1-2.7.71l-.1,0Z" />
                    <path fill="currentColor" d="M6.14,7.14A3.6,3.6,0,0,1,2.57,3.57a3.57,3.57,0,0,1,7.14,0A3.59,3.59,0,0,1,6.14,7.14Z" /></g></g></svg>

              <div className="sideNav-item mb-0 ">My Profile</div>
            </div>
          </Link>
        </div>

        {userRole === AppConstants.UserRoles.Admin ||
          userRole === AppConstants.UserRoles.ResellerAdmin ? (
          <>
            <a
              className="list-group-item list-group-item-action collapsed"
              data-toggle="collapse"
              href="#admin-sidebar-menu"
              role="button"
              aria-expanded="false"
              aria-controls="admin-sidebar-menu"
              onClick={handleSubMenuClick}
            >
              <div className="d-flex align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.34 16.4">
                  <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                      <path fill="currentColor" d="M12.61,15.05c-.13-.06-.27-.1-.39-.17s-.51-.34-.76-.52a.21.21,0,0,0-.24,0l-1.08.46a.19.19,0,0,0-.1.13c0,.24-.08.49-.1.74a.75.75,0,0,1-.81.73H7.08a.69.69,0,0,1-.81-.72c0-.25-.07-.5-.11-.74a.2.2,0,0,0-.11-.14l-1-.46a.17.17,0,0,0-.22,0l-.68.51a.72.72,0,0,1-.95-.06L1.65,13.26a.63.63,0,0,1-.09-.87c.17-.25.34-.49.49-.74a.31.31,0,0,0,0-.21c-.15-.39-.31-.78-.47-1.17a.22.22,0,0,0-.13-.1l-.78-.1A.74.74,0,0,1,0,9.29V7.18A.7.7,0,0,1,.7,6.4l.75-.1a.23.23,0,0,0,.15-.12c.17-.39.33-.8.48-1.2a.14.14,0,0,0,0-.14c-.16-.23-.33-.46-.5-.68a.71.71,0,0,1,.06-1L3.12,1.67a.64.64,0,0,1,.94-.07l.7.52c.07.06.12,0,.2,0a3.36,3.36,0,0,1,1.1-.47.2.2,0,0,0,.1-.12c0-.28.08-.56.11-.84A.74.74,0,0,1,7,0H9.15a.7.7,0,0,1,.78.66c.06.29.11.59.17.88a.22.22,0,0,0,.12.13l1.16.46a.27.27,0,0,0,.2,0l.78-.54a.71.71,0,0,1,.91.07c.51.51,1,1,1.52,1.53a.71.71,0,0,1,0,1c-.18.24-.37.48-.54.72a.2.2,0,0,0,0,.17c.14.34.3.68.44,1a.23.23,0,0,0,.19.16l.85.15a.68.68,0,0,1,.59.73c0,.7,0,1.39,0,2.09,0,.55-.16.75-.7.85l-.83.17a.25.25,0,0,0-.14.11c-.16.33-.3.68-.46,1a.2.2,0,0,0,0,.23l.52.75a.72.72,0,0,1-.07.94L13.15,14.8A.91.91,0,0,1,12.61,15.05ZM4.36,11.39c.22-.22.41-.45.64-.65a5.27,5.27,0,0,1,1.57-.92.69.69,0,0,1,.61,0A3.35,3.35,0,0,0,8,10a2.89,2.89,0,0,0,1.36-.22.75.75,0,0,1,.62,0c.25.13.51.23.75.36a5,5,0,0,1,1.42,1.22l.16-.21a7.26,7.26,0,0,0,1-2.24,3.51,3.51,0,0,0,.09-1.51A5,5,0,0,0,7.51,3.23,5.1,5.1,0,0,0,3.56,9.91,5.29,5.29,0,0,0,4.36,11.39Z" />
                      <path fill="currentColor" d="M6.15,6.91a2,2,0,0,1,2-2,2,2,0,0,1,2.06,2A2.06,2.06,0,0,1,8.21,9,2,2,0,0,1,6.15,6.91Z" /></g></g></svg>

                <div className="sideNav-item mb-0 ">Admin Panel</div>
              </div>
            </a>
            <ul
              className="nav  flex-column collapse collapsable-sidemenu-item flex-nowrap collapsibleMenuHighlighter"
              id="admin-sidebar-menu"
            >
              {AccessControl.Company ? (
                <>
                  <li className="nav-item sub-menu-item">
                    <Link
                      to={AppRouteUi.Company.Root}
                      className={toggleActiveTab(AppRouteUi.Company.Root)}
                    >
                      Company
                    </Link>
                  </li>
                </>
              ) : null}
              {AccessControl.Reseller ? (
                <>
                  <li className="nav-item sub-menu-item">
                    <Link
                      to={AppRouteUi.Reseller.Root}
                      className={toggleActiveTab(AppRouteUi.Reseller.Root)}
                    >
                      Reseller
                    </Link>
                  </li>
                </>
              ) : null}
              {
                // AccessControl.Vendors ? (
                //   <>
                //     <li className="nav-item sub-menu-item">
                //       <Link
                //         to={AppRouteUi.Vendors.Root}
                //         className={toggleActiveTab(AppRouteUi.Vendors.Root)}
                //       >
                //         Vendors
                //       </Link>
                //     </li>
                //   </>
                // ) : null
              }

              {/* <li className="nav-item sub-menu-item">
                    <Link
                      to={AppRouteUi.PhoneNumber.Root}
                      className={toggleActiveTab(AppRouteUi.PhoneNumber.Root)}
                    >
                      Phone Number
                    </Link>
                  </li> */}
              {AccessControl.Call_Outflow ? (
                <>
                  <li className="nav-item sub-menu-item">
                    <Link
                      to={AppRouteUi.CallOutflow.Root}
                      className={toggleActiveTab(AppRouteUi.CallOutflow.Root)}
                    >
                      Call Outflow
                    </Link>
                  </li>
                </>
              ) : null}
              {AccessControl.User ? (
                <>
                  <li className="nav-item sub-menu-item">
                    <Link
                      to={AppRouteUi.User.Root}
                      className={toggleActiveTab(AppRouteUi.User.Root)}
                    >
                      User
                    </Link>
                  </li>
                </>
              ) : null}
              {AccessControl.TIN ? (
                <>
                  <li className="nav-item sub-menu-item">
                    <Link
                      to={AppRouteUi.TIN.Root}
                      className={toggleActiveTab(AppRouteUi.TIN.Root)}
                    >
                      TIN
                    </Link>
                  </li>
                </>
              ) : null}
              {AccessControl.Language ? (
                <>
                  <li className="nav-item sub-menu-item">
                    <Link
                      to={AppRouteUi.Language.Root}
                      className={toggleActiveTab(AppRouteUi.Language.Root)}
                    >
                      Language
                    </Link>
                  </li>
                </>
              ) : null}
              {AccessControl.Language_Packs ? (
                <>
                  <li className="nav-item sub-menu-item">
                    <Link
                      to={AppRouteUi.LanguagePacks.Root}
                      className={toggleActiveTab(AppRouteUi.LanguagePacks.Root)}
                    >
                      Language Packs
                    </Link>
                  </li>
                </>
              ) : null}
              {AccessControl.Skill ? (
                <>
                  <li className="nav-item sub-menu-item">
                    <Link
                      to={AppRouteUi.Skill.Root}
                      className={toggleActiveTab(AppRouteUi.Skill.Root)}
                    >
                      Skill
                    </Link>
                  </li>
                </>
              ) : null}
              {AccessControl.Modality ? (
                <>
                  <li className="nav-item sub-menu-item">
                    <Link
                      to={AppRouteUi.Modality.Root}
                      className={toggleActiveTab(AppRouteUi.Modality.Root)}
                    >
                      Modality
                    </Link>
                  </li>
                </>
              ) : null}
              {AccessControl.AppointmentCategory ? (
                <>
                  <li className="nav-item sub-menu-item">
                    <Link
                      to={AppRouteUi.AppointmentCategory.Root}
                      className={toggleActiveTab(AppRouteUi.AppointmentCategory.Root)}
                    >
                      Appointment Type
                    </Link>
                  </li>
                </>
              ) : null}
              {AccessControl.AppointmentDescription ? (
                <>
                  <li className="nav-item sub-menu-item">
                    <Link
                      to={AppRouteUi.AppointmentDescription.Root}
                      className={toggleActiveTab(AppRouteUi.AppointmentDescription.Root)}
                    >
                      Description
                    </Link>
                  </li>
                </>
              ) : null}
              {AccessControl.Vendor ? (
                <>
                  <li className="nav-item sub-menu-item">
                    <Link
                      to={AppRouteUi.Vendors.Root}
                      className={toggleActiveTab(AppRouteUi.Vendors.Root)}
                    >
                      Vendors
                    </Link>
                  </li>
                </>
              ) : null}
              {AccessControl.Audit_Logs ? (
                <>
                  <li className="nav-item sub-menu-item">
                    <Link
                      to={AppRouteUi.AuditLogs.Root}
                      className={toggleActiveTab(AppRouteUi.AuditLogs.Root)}
                    >
                      Audit Logs
                    </Link>
                  </li>
                </>
              ) : null}
            </ul>
          </>
        ) : null}
        {AccessControl.Company_Profile ? (
          <div
            className={
              location.pathname === AppRouteUi.CompanyProfile.Root
                ? "activeSinglenav"
                : ""
            }
          >
            <Link
              to={AppRouteUi.CompanyProfile.Profile.Load(userCompany ?? "")}
              className="list-group-item list-group-item-action collapsed"
            >
              <div className="d-flex align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.29 13.75">
                  <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                      <path fill="#4d4d4f" d="M6.14,7.14A3.57,3.57,0,1,0,6.14,0,3.6,3.6,0,0,0,2.57,3.57,3.6,3.6,0,0,0,6.14,7.14Z" />
                      <path fill="#4d4d4f" d="M5.79,13.75l-.65-.07a6.67,6.67,0,0,1-2.3-.74A7.15,7.15,0,0,1,.08,10.38a.44.44,0,0,1,0-.48,4.43,4.43,0,0,1,3-2.42l.33-.06A.68.68,0,0,1,4,7.56a3.7,3.7,0,0,0,2.37.68,3.31,3.31,0,0,0,1.81-.57,1.27,1.27,0,0,1,1.2-.16,4.53,4.53,0,0,1,2.75,2.11c.27.45.28.46,0,.9A7.09,7.09,0,0,1,9.26,13a6.85,6.85,0,0,1-2.7.71l-.1,0Z" />
                      <path fill="#4d4d4f" d="M6.14,7.14A3.6,3.6,0,0,1,2.57,3.57a3.57,3.57,0,0,1,7.14,0A3.59,3.59,0,0,1,6.14,7.14Z" /></g></g></svg>

                <div className="sideNav-item mb-0 ">Company Profile</div>
              </div>
            </Link>
          </div>
        ) : (
          <></>
        )}

        {AccessControl.My_Account ||
          AccessControl.Finance_Company ||
          AccessControl.Operator ? (
          <>
            <a
              className="list-group-item list-group-item-action collapsed"
              data-toggle="collapse"
              href="#finance-sidebar-menu"
              role="button"
              aria-expanded="false"
              aria-controls="finance-sidebar-menu"
              onClick={handleSubMenuClick}
            >
              <div className="d-flex align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.56 10.32">
                  <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1">
                    <path fill="currentColor" d="M12.13,0H1.42A1.43,1.43,0,0,0,0,1.42V8.9a1.43,1.43,0,0,0,1.42,1.42H12.13A1.43,1.43,0,0,0,13.56,8.9V1.42A1.43,1.43,0,0,0,12.13,0ZM6.94,7.21H2.34A.61.61,0,0,1,2.34,6h4.6a.61.61,0,0,1,0,1.21Zm3.78-3.09H2.34a.61.61,0,0,1,0-1.21h8.38a.61.61,0,1,1,0,1.21Z" /></g></g></svg>

                <div className="sideNav-item mb-0 ">Finance</div>
              </div>
            </a>
            <ul
              className="nav  flex-column collapse collapsable-sidemenu-item flex-nowrap collapsibleMenuHighlighter"
              id="finance-sidebar-menu"
            >
              {AccessControl.My_Account ? (
                <li className="nav-item sub-menu-item">
                  <Link
                    to={userRole === AppConstants.UserRoles.CompanyFinance || userRole === AppConstants.UserRoles.CompanyAdmin ? AppRouteUi.CompanyFinance.Profile.Load(userCompany ?? "") : AppRouteUi.Account.Root}
                    className={userRole === AppConstants.UserRoles.CompanyFinance || userRole === AppConstants.UserRoles.CompanyAdmin ? toggleActiveTab(AppRouteUi.CompanyFinance.Profile.Load(userCompany ?? "")) : toggleActiveTab(AppRouteUi.Account.Root)}
                  >
                    My Account
                  </Link>
                </li>
              ) : null}
              {AccessControl.Finance_Company ? (
                <li className="nav-item sub-menu-item">
                  <Link
                    to={AppRouteUi.CompanyFinance.Root}
                    className={toggleActiveTab(AppRouteUi.CompanyFinance.Root)}
                  >
                    Company
                  </Link>
                </li>
              ) : null}
              {AccessControl.Operator ? (
                <li className="nav-item sub-menu-item">
                  <Link
                    to={AppRouteUi.OperatorFinance.Root}
                    className={toggleActiveTab(AppRouteUi.OperatorFinance.Root)}
                  >
                    Operator
                  </Link>
                </li>
              ) : null}
            </ul>
          </>
        ) : null}

        {menuSettings?.callCenterSettings?.callCenterEnabled ? (
          <>
            <a
              className="list-group-item list-group-item-action  collapsed"
              data-toggle="collapse"
              href="#call-center-sidebar-menu"
              role="button"
              aria-expanded="false"
              aria-controls="call-center-sidebar-menu"
              onClick={handleSubMenuClick}
            >
              <div className="d-flex align-items-center">
                {/* <AssetSvg./> */}
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.7 17.85">
                    <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path fill="currentColor" d="M15.69,8.91a1.49,1.49,0,0,0-.89-1.39.24.24,0,0,1-.15-.26,6.84,6.84,0,0,0-.49-3A6.63,6.63,0,0,0,8.18,0a6.38,6.38,0,0,0-4.9,1.77A6.59,6.59,0,0,0,1.09,6.26a11.27,11.27,0,0,0,0,1.16A1.59,1.59,0,0,0,0,8.87c0,.73,0,1.46,0,2.19a1.5,1.5,0,0,0,.63,1.21,1.57,1.57,0,0,0,1,.31H4.18V7.34H2.12A5.77,5.77,0,0,1,4.93,1.86a5.62,5.62,0,0,1,6.35.33A5.82,5.82,0,0,1,13.6,7.34H11.52v5.24H13.6v1.53A1.56,1.56,0,0,1,12,15.75H9.53a.27.27,0,0,1-.28-.18,1.56,1.56,0,0,0-1.54-.87,1.58,1.58,0,0,0-1.37,1.15,1.53,1.53,0,0,0,.58,1.67,5.23,5.23,0,0,0,.72.32H8.1l.09,0a1.51,1.51,0,0,0,1.08-.88.2.2,0,0,1,.22-.13H12a2.62,2.62,0,0,0,2.63-2.64V12.65a.19.19,0,0,1,.12-.21A1.53,1.53,0,0,0,15.69,11C15.7,10.31,15.71,9.61,15.69,8.91Z" /></g></g></svg>

                </span>
                <div className="sideNav-item mb-0 ">Call Center</div>
              </div>
            </a>
            <ul
              className="nav  flex-column collapse collapsable-sidemenu-item flex-nowrap collapsibleMenuHighlighter"
              id="call-center-sidebar-menu"
            >
              <li className="nav-item sub-menu-item">
                <Link
                  to={AppRouteUi.Operator.Root}
                  className={toggleActiveTab(AppRouteUi.Operator.Root)}
                >
                  Operator
                </Link>
              </li>
              <li className="nav-item sub-menu-item">
                <Link
                  to={AppRouteUi.Activity.Root}
                  className={toggleActiveTab(AppRouteUi.Activity.Root)}
                >
                  Activity
                </Link>
              </li>
              <li className="nav-item sub-menu-item">
                <Link
                  to={AppRouteUi.Message.Root}
                  className={toggleActiveTab(AppRouteUi.Message.Root)}
                >
                  Message
                </Link>
              </li>
              {menuSettings?.callCenterSettings?.inflowEnabled ? (
                <>
                  <li className="nav-item sub-menu-item">
                    <Link
                      to={AppRouteUi.CallInflow.Root}
                      className={toggleActiveTab(AppRouteUi.CallInflow.Root)}
                    >
                      Call Inflow
                    </Link>
                  </li>
                </>
              ) : null}
            </ul>
          </>
        ) : null}

        {(UserAgentUtils.isWebView() || UserAgentUtils.isMobileBrowser()) && (
          <div className={
            location.pathname === AppRouteUi.Log.Root
              ? 'activeSinglenav'
              : ''
          }>
            <Link
              to={AppRouteUi.Log.Root}
              className="list-group-item list-group-item-action collapsed"
            >
              <div className="d-flex align-items-center">
                <div className="svg-color" title="log">
                  <FontAwesomeIcon icon={faReceipt} style={{width: '15.5px', color: '#4D4D4F'}}/>
                </div>
                <div className="sideNav-item mb-0 ">Log</div>
              </div>
            </Link>
          </div>
        )}

        {(!UserAgentUtils.isElectron() && !UserAgentUtils.isWebView()) && (
          <div
            className={
              location.pathname === AppRouteUi.CompanyProfile.Root
                ? 'activeSinglenav'
                : ''
            }
          >
            <Link
              to={AppRouteUi.Download.root}
              className="list-group-item list-group-item-action collapsed"
            >
              <div className="d-flex align-items-center">
                <div className="svg-color" title="download">
                  <AssetSvg.DownloadV2 />
                </div>
                <div className="sideNav-item mb-0 ">Download</div>
              </div>
            </Link>
          </div>
        )}

        {!UserAgentUtils.isWebView() && <div className="dropdown-divider d-block d-lg-none d-xl-none mb-3" />}
        <SocketContext.Consumer>
          {(connection) => (
            <Link
              to={AppRouteUi.Login.Root}
              className={`list-group-item list-group-item-action collapsed d-block  ${!UserAgentUtils.isWebView() ? 'd-sm-block  d-md-block d-lg-none d-xl-none' : ''}`}
              onClick={(event) => {
                event.preventDefault();
                connection.socketConnection?.close();
                onLogout();
              }}
            >
              <i className="s7 s7-power icon-call mr-3" />
              Log out
            </Link>
          )}
        </SocketContext.Consumer>
      </div>
    </div>
  );
}
